<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('failures.resolutionForm.header') }}
      </p>
    </header>

    <section class="modal-card-body">
      <a-card
        :editable="false"
      >
        <a-input
          field="resolution"
          type="textarea"
          :label="$t('failures.resolutionForm.type')"
        />
      </a-card>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-dark"
        @click="close"
      >
        {{ $t('common.close') }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    close () {
      this.$parent.close()
    }
  }
}
</script>

<style scoped>
.modal-card {
    width: auto;
}
.modal-card-body {
    width: auto;
}
</style>
